<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password -->
      <b-card class="mb-0">
        <b-card-title class="mb-1"> Forgot Password? </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you an e-mail to reset your password
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group label="Email" label-for="forgot-password-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button v-if="!loading" variant="primary" block type="submit">
              Send reset link
            </b-button>
            <div v-else class="text-center">
              <b-spinner variant="primary" type="grow" label="Spinning" />
            </div>
          </b-form>
        </validation-observer>

        <b-card-text v-if="!loading" class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password -->
    </div>
  </div>
</template>

<script>
import Auth from "@/services/auth.service.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email as vEmail } from "@validations";

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    loading: false,
    email: null,
    required,
    vEmail,
  }),
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          this.loading = true;
          try {
            const { data } = await Auth.sendResetPass(this.email);
            this.$swal({
              icon: "success",
              title: "Good",
              text: data,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } catch (e) {
            this.$swal({
              icon: "error",
              title: "Faild to send a reset link..",
              text: e?.response?.data || "something went wrong",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
